<template>
    <div>
        <div class="classes w-100 ftco-animate fadeInUp ftco-animated" @click="GoToContent()">
            <a :href="Item.fulldescmode == 'external' ? '#TraningSeminar' : '/activity/'+Name" class="img" :style="'background-image: url('+(Banner != '' ? (imgApiURL + Banner) : imageDefault)+'); background-position: center top; background-size: 100% auto;'"></a>
            <div class="text w-100 text-center">
              <div class="meta">
                <p>
                  <a href="#"><i class="ni ni-calendar-grid-58 mr-1"></i> {{StartDate}}</a> <br>
                  <a href="#" class="meta-chat"><i class="ni ni-time-alarm"></i> {{StartTime}}</a>
                </p>
              </div>
              <h3>{{Name}}</h3>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        Name:String,
        Banner:String,
        TargetURL:String,
        Item:Object
    },
    data(){
        return{
            imageDefault: require('../assets/images/image_not_available.png'),
            imgApiURL: this.$bkURL+"imgs/",
            StartDate:"",
            StartTime:""
        }
    },
    methods:{        
        itemToDateTime(){
            let Temp = new Date(this.Item.startdate);
            
            let year = Temp.getFullYear();
            let month = ('0' + (Temp.getMonth() + 1)).slice(-2);
            let date = ('0' + Temp.getDate()).slice(-2);
            let hour = ('0' + Temp.getHours()).slice(-2);
            let minute = ('0' + Temp.getMinutes()).slice(-2);
            
            // this.StartDate = year+"-"+month+"-"+date;
            this.StartDate = date+"/"+month+"/"+year;
            this.StartTime = hour+":"+minute;
        },        
        GoToContent(){
            if(this.Item != null){
                if(this.Item.fulldescmode == "external"){
                    window.open(this.Item.exturl, '_blank')
                }
                else{
                    this.$router.push("/activity/"+this.Item.name);
                }
            }
        }
    },
    mounted(){
        this.itemToDateTime();
    }
}
</script>

<style scoped>

</style>