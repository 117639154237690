<template>
    <div>
        <div class="row">
            <div :class="MiniView ? 'col-sm-12' : 'col-sm-10'">
                <div class="row">
                    <div class="col-sm-4" v-for="(cl,index) in ShowItem.slice(0,SplitIdx)" :key="index">
                        <cl-card
                            v-bind:Name="cl.name"
                            v-bind:Banner="cl.pic"
                            v-bind:Item="cl"
                        >
                        </cl-card>
                    </div>
                </div>
            </div>

            <div v-if="MiniView != true" class="col-sm-2">
                <div class="row">
                    <b-card class="Cate-Card" no-body>
                        <div class="pt-2 pb-2">
                            <h6>Search</h6>
                            <b-input placeholder="Name" v-model="SearchText"></b-input>
                            <h6 class="mt-2">Category</h6>

                            <div v-for="(cate, ix) in Cates" :key="ix">
                                <b-button class="btn-filter-link" block @click="FilterByCate(cate)" :variant="FilterTag == cate? 'primary' : 'link'">{{cate}}</b-button>
                            </div>
                        </div>                        
                    </b-card>
                    
                </div>
            </div>
        </div>

        <div class="row" v-if="MiniView == true && Items.length > MinShow">
            <div class="col-sm-12">
                <a href="/activities" class="btn btn-secondary pull-right">more</a>
            </div>
        </div>
    </div>
</template>

<script>
import SiteDataService from "../services/SiteDataService.vue"
import ClassCard from "./ClassCard.vue"

export default {
    props:{
        MiniView:Boolean,
    },
    components:{
        "cl-card":ClassCard
    },
    data(){
        return{
            Items:[],
            SplitIdx:3,
            MinShow:3,
            Cates:[],
            ShowItem:[],
            SearchText:"",
            FilterTag:"",
            LoadCate:""
        }
    },
    methods:{
        GetItemLists(){
            SiteDataService.ActivityGets()
                .then(res => {
                    this.Items = [];
                    this.Items = res.data;
                    this.ShowItem = this.Items;

                    if(this.MiniView != true){
                        this.SplitIdx = this.Items.length;
                        this.Cates = ["All"];

                        for(let i = 0; i < this.Items.length; i++){
                            let _item = this.Items[i];
                            var chk = this.Cates.filter(el => el == _item.category)
                            if(chk == null || chk.length == 0)
                                this.Cates.push(_item.category);
                        }

                        if(this.LoadCate != "")
                            this.FilterByCate(this.LoadCate);
                    }
                    else{
                        this.SplitIdx = (this.Items.length > this.MinShow ? this.MinShow : this.Items.length);
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        },
        FilterByCate(Cate){
            if(Cate == "All"){
                this.ShowItem = this.Items;
                this.FilterTag = "";
            }
            else{
                this.ShowItem = this.Items.filter(x => x.category == Cate);            
                this.FilterTag = Cate;
            }
        },
        FilterByName(Key){
            let _Key = Key.toLowerCase();
            if(_Key == ""){
                this.ShowItem = this.Items;
                this.FilterTag = "";
            }
            else{
                this.ShowItem = this.Items.filter(x => x.name.toLowerCase().indexOf(_Key) > -1);                
                this.FilterTag = Key;
            }
        },
    },
    watch:{
        SearchText(n){
            this.FilterByName(n);
        }
    },
    mounted(){
        this.GetItemLists();
        if(this.$route.params.cate != null)
            this.LoadCate = this.$route.params.cate;
    }
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;600;700&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Reenie+Beanie&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kanit&display=swap");

.Cate-Card{
    border: none;
}

.btn-filter-link{
  border-bottom: 1px solid #ccc;
  width: 100%;
  text-align: left;
}

</style>