<template>
    <div>
        <!-- Header Menu -->        
        <b-navbar toggleable="lg" type="dark" variant="info" 
            :class="sc_scroll > 0 ? 
                    'navbar-dark ftco_navbar bg-dark ftco-navbar-light scrolled awake' :
                    'navbar-dark ftco_navbar bg-dark ftco-navbar-light'
                    " 
            id="ftco-navbar"
        >
            <div class="container">
                <b-navbar-brand href="/home"><img :src="MiniLogo" class="img-mini-logo" /></b-navbar-brand>
                <!-- <b-navbar-brand href="/home">{{site_content.shortname}}</b-navbar-brand> -->                

                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

                <b-collapse id="nav-collapse" class="" is-nav>

                <!-- Right aligned nav items -->
                <!-- <div class="collapse navbar-collapse" id="ftco-nav">                 -->
                    <b-navbar-nav class="ml-auto">
                        <b-nav-item v-scroll-to="{ el: '#TopPage'}">{{topics[0].val}}</b-nav-item>
                        <b-nav-item v-scroll-to="{ el: '#about', offset: -120 }">{{topics[1].val}}</b-nav-item>
                        <b-nav-item v-scroll-to="{ el: '#TrainingSeminar', offset: -60 }">{{topics[2].val}}</b-nav-item>
                        <b-nav-item v-scroll-to="{ el: '#ChoirMinistry', offset: -60 }">{{topics[3].val}}</b-nav-item>
                        <b-nav-item v-scroll-to="{ el: '#News', offset: -60 }">{{topics[4].val}}</b-nav-item>
                        <b-nav-item v-scroll-to="{ el: '#contact', offset: -60 }">{{topics[5].val}}</b-nav-item>
                        <b-nav-item @click="mdLanguage = true">
                            <img class="img-lang" :src="showLanguage.pic" />
                        </b-nav-item>
                    </b-navbar-nav>
                <!-- </div> -->
                </b-collapse>
            </div>
        </b-navbar>

        <!-- Modal Language -->
        <b-modal size="sm" v-model="mdLanguage" no-close-on-backdrop no-close-on-esc hide-header hide-footer>
            <b-row>
                <b-col>
                    <b-container fluid>
                        <h6>Language:</h6>
                        <b-row>
                            <b-col sm="2" class="text-center">
                                <img class="img-lang" :src="showLanguage.pic" />                                
                            </b-col>
                            <b-col>
                                <select class="form-control" v-model="showLanguage">
                                    <option v-for="(lang, index) in langs" :key="index"
                                        v-bind:value="lang"
                                    >
                                        {{lang.name}}
                                    </option>
                                </select>
                            </b-col>
                        </b-row>
                        
                        <b-button variant="outline-secondary" class="mt-2 pull-right" size="sm" @click="mdLanguage = false"><b-icon-x></b-icon-x> Cancel</b-button>
                    </b-container>
                </b-col>
            </b-row>
        </b-modal>

        <!-- Top Page -->
        <section id="TopPage" class="hero-wrap degree-right js-fullheight" :style="'background-image:url('+topimg+'); height:'+sc_high+'px;'">
            <div class="overlay"></div>
            <div class="overlay-2"></div>
            <div class="container">
            <div class="row no-gutters slider-text js-fullheight align-items-center" :style="'height:'+sc_high+'px;'" data-scrollax-parent="true">
                <div class="col-md-6">
                    <div class="mb-5">
                        <span class="subheading">{{showLanguage.lang == 'en' ? 'Welcome to ' : 'ยินดีต้อนรับเข้าสู่ '}}{{site_content.shortname}}</span>
                        <h1 class="mb-4">{{site_content.name}}</h1>
                        <p class="mb-4">{{site_content.welcometext}}</p>
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-right justify-content-end">
                    <div>
                        <!-- <h5 class="text-light">We are open every day.</h5> -->
                        <h6 class="pull-right align-middle">                            
                            <button class="btn-join pull-right" v-b-tooltip.hover title="Register" @click="GoToRegister()">Sign Up</button>
                            <div class="vl pull-right"></div> 
                            <button class="btn-signin pull-right" v-b-tooltip.hover title="Member Login" @click="GoToMember()"><u>Sign In</u></button> 
                        </h6>
                    </div>                    
                </div>
            </div>
            </div>
        </section>
       
        <!-- About Section -->
        <section class="ftco-section-mini ftco-no-pb ftco-about bg-light img">
            <div class="container" id="about">
                <div class="row d-flex">
                    <div class="col-sm-12 ql-content" v-html="site_content.about_html"></div>
                </div>
            </div>
        </section>        
        
        <!-- Status Section -->
        <section class="ftco-section ftco-counter img" id="section-counter" style="background-image: url(images/bg_2.jpg);">
            <div class="overlay"></div>
            <div class="container">
            <div class="row">
                <div class="col-md-6 col-lg-4 d-flex counter-wrap ftco-animated">
                <div class="block-18 mb-xl-0 mb-2 d-flex align-items-center">
                    <div class="icon d-flex align-items-center justify-content-center"><span><b-icon-globe></b-icon-globe></span>
                    </div>
                    <div class="text pl-3">
                    <!-- <strong class="number" data-number="150">0</strong> -->
                        <strong class="stat-number">
                            <h4 class="text-light"><b>WACCM</b></h4>                            
                            <!-- <animated-number :value="site_stat.traning" :formatValue="StatFormat" :duration="site_stat.duration" /> -->
                        </strong>
                        <span><a href="https://waccm.com/" class="text-light" target="_blank"><u>Partners</u></a></span>
                    </div>
                </div>
                </div>
                <div class="col-md-6 col-lg-4 d-flex counter-wrap ftco-animated">
                <div class="block-18 mb-xl-0 mb-2 d-flex align-items-center">
                    <div class="icon d-flex align-items-center justify-content-center"><span><b-icon-people></b-icon-people></span>
                    </div>
                    <div class="text pl-3">
                    <!-- <strong class="number" data-number="1000">0</strong> -->
                        <strong class="stat-number">
                            <!-- <animated-number :value="site_stat.member" :formatValue="StatFormat" :duration="site_stat.duration" /> -->
                            <animated-number :value="site_stat.visitor" :formatValue="StatFormat" :duration="site_stat.duration" />
                        </strong>
                        <span>Visitors</span>                    
                    </div>
                </div>
                </div>
                <div class="col-md-6 col-lg-4 d-flex counter-wrap ftco-animated">
                <div class="block-18 mb-xl-0 mb-2 d-flex align-items-center">
                    <div class="icon d-flex align-items-center justify-content-center"><span><b-icon-clock-history></b-icon-clock-history> </span>
                    </div>
                    <div class="text pl-3">
                        <strong class="stat-number">
                            <animated-number :value="site_stat.yearexp" :formatValue="StatFormat" :duration="site_stat.duration" />
                        </strong>
                        <span>Years of Experienced</span>
                    </div>
                </div>
                </div>
                <!-- <div class="col-md-6 col-lg-3 d-flex counter-wrap ftco-animated">
                <div class="block-18 mb-xl-0 mb-2 d-flex align-items-center">
                    <div class="icon d-flex align-items-center justify-content-center"><span
                        class="flaticon-mindfulness"></span></div>
                    <div class="text pl-3">
                    <strong class="number" data-number="80">0</strong>
                    <span>Chior Ministry</span>
                    </div>
                </div>
                </div> -->
            </div>
            </div>
        </section>

        <!-- Class Section -->
        <section class="ftco-section">
            <div class="container" id="TrainingSeminar">
                <div class="row justify-content-center pb-5">
                    <div class="col-md-12 heading-section text-center ftco-animated">                    
                        <h2 class="mb-4">{{topics[2].val}}</h2>
                        <span class="subheading">Our Activites</span>
                    </div>
                </div>                
                <ClassListComp v-bind:MiniView="true"></ClassListComp>
            </div>
        </section>

        <!-- Teacher Section -->
        <section class="ftco-section bg-light">
            <div class="container" id="ChoirMinistry">
                <div class="row justify-content-center pb-5">
                    <div class="col-md-12 heading-section text-center ftco-animated">                    
                        <h2 class="mb-4">{{topics[3].val}}</h2>
                        <span class="subheading">CMA ADULT CHOIR &amp; CMA ANGELS CHOIR</span>
                    </div>
                </div>
                <ChoirMinistryListComp v-bind:MiniView="true" v-bind:HideLockConent="true"></ChoirMinistryListComp>
            </div>
        </section>

        <!-- Blog Section -->
        <section class="ftco-section">
            <div class="container" id="News">
                <div class="row justify-content-center pb-4">
                    <div class="col-md-12 heading-section text-center ftco-animated">                        
                        <h2 class="mb-4">{{topics[4].val}}</h2>
                        <span class="subheading">LASTEST NEWS</span>
                    </div>
                </div>
                <BlogListComp v-bind:MiniView="true"></BlogListComp>
            </div>
        </section>

        <!-- Footer -->
        <footer class="ftco-footer">
            <div class="container" id="contact">
                <div class="row mb-5 justify-content-between">
                    <div class="col-sm-12 col-md-5">
                        <div class="ftco-footer-widget mb-4">
                            <h2 class="ftco-heading-2 logo"><a href="#">{{site_content.shortname}}</a></h2>
                            <p>{{site_content.name}} <br/> {{site_content.welcometext}}</p>
                            <ul class="ftco-footer-social list-unstyled mt-2">
                                <li class=""><a :href="site_content.facebookurl" target="_blank"><span class="fa fa-facebook-square"></span></a></li>
                                <li ><a :href="site_content.youtubeurl" target="_blank" class="youtube"><span class="fa fa-youtube-square"></span></a></li>
                                <!-- <li ><a :href="site_content.lineurl" target="_blank" class="line"><span>L</span></a></li> -->
                            </ul>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-2">
                    </div>
                    <div class="col-sm-12 col-md-4">
                        <div class="ftco-footer-widget mb-4">
                            <h2 class="ftco-heading-2">Have a Questions?</h2>
                            <div class="block-23 mb-3">
                            <ul>
                                <li><span class="icon fa fa-map marker"></span><span class="text">{{site_content.addr}}</span></li>
                                <li><a href="#"><span class="icon fa fa-phone"></span><span class="text">{{site_content.phone}}</span></a></li>
                                <li><a href="#"><span class="icon fa fa-paper-plane pr-4"></span><span class="text">
                                    <span>{{site_content.email}}</span></span></a>
                                </li>
                            </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid px-0 py-1 bg-black">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <p class="mb-0" style="color: rgba(255,255,255,.5);">
                                Copyright &copy;2021 All rights reserved 
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
import AnimatedNumber from "animated-number-vue";
import ClassListComp from "../components/ClassListComp.vue";
// import TeacherListComp from "../components/TeacherListComp.vue";
import ChoirMinistryListComp from "../components/ChoirMinistryListComp.vue";
import BlogListComp from "../components/BlogListComp.vue";
import SiteDataService from "../services/SiteDataService.vue";

export default {
    components: {
        AnimatedNumber,
        ClassListComp,
        // TeacherListComp,
        ChoirMinistryListComp,
        BlogListComp
    },
    data(){
        return{
            sc_high: 350,
            sc_width: 1366,
            sc_scroll: 0,
            site_content:{},
            mdLanguage:false,
            showLanguage:{lang:"en",pic: require("../assets/images/united-states.png")},
            langs:[
                {name:"English", lang:"en",pic: require("../assets/images/united-states.png")},
                {name:"Thai", lang:"th",pic: require("../assets/images/thailand.png")}
            ],
            site_stat:{
                traning:200,
                member:1200,
                yearexp:1,
                duration:3000,
                visitor:0
            },
            topimg: require("../assets/images/head1.jpg"),
            MiniLogo:require("../assets/images/tcma-logo-new.png"),
            i18n:[],
            topics:[
                {id:1, val:"Home"},
                {id:2, val:"About"},
                {id:3, val:"Training&Seminar"},
                {id:4, val:"CMA Choir"},
                {id:5, val:"News"},
                {id:6, val:"Contact"},
            ],
            activelang:"en"
        }
    },
    methods:{
        windowSizeChange(e){
            this.sc_high = e.target.innerHeight;
            this.sc_width = e.target.innerWidth;
            this.sc_scroll = 0;
        },
        windowScroll(e){
            this.sc_scroll = e.target.documentElement.scrollTop;
        },
        GetSiteData(lang){
            SiteDataService.SiteContentGet(lang)
                .then(res => {
                    this.site_content = res.data;
                    if(this.site_content.homebanner != null)
                        this.topimg = this.$bkURL +"imgs/"+this.site_content.homebanner;
                    else
                        this.topimg = require("../assets/images/head1.jpg");
                    
                    if(this.i18n.length == 0){
                        this.activelang = lang;
                        this.GetMenulang();
                    }
                    else{
                        this.topics = [];
                        for(let i = 0; i < this.i18n.length; i++){
                            let _i18n = this.i18n[i];
                            let _tp = {
                                id: _i18n.id,
                                val: (lang == "en"? _i18n.en : _i18n.th)
                            };
                            this.topics.push(_tp);
                        }
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        },  
        GetMenulang(){
            SiteDataService.GetMenuMultiLanguage()
                .then(res => {
                    this.i18n = res.data;
                    if(this.i18n.length > 0)
                        this.topics = [];
                    for(let i = 0; i < this.i18n.length; i++){
                        let _i18n = this.i18n[i];
                        let _tp = {
                            id: _i18n.id,
                            val: (this.activelang == "en"? _i18n.en : _i18n.th)
                        };
                        this.topics.push(_tp);
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        },    
        GoToRegister(){
            this.$router.push("/Register");
        },
        GoToMember(){
            this.$router.push("/Member");
        },
        ShowModalChangeLanguage(){
            this.mdLanguage = true;
        },
        SetShowLanguage(slang){
            let _lang = this.langs.filter(f => f.lang == slang);
            if(_lang != null && _lang.length > 0){
                this.showLanguage = _lang[0];
                this.GetSiteData(_lang[0].lang);
            }
            else
                this.GetSiteData("en");
        },
        CalculateYearOfExp() {
            let birthday = new Date(2018,1,16);
            var ageDifMs = Date.now() - birthday;
            var ageDate = new Date(ageDifMs); // miliseconds from epoch
            return Math.abs(ageDate.getUTCFullYear() - 1970);
        },
        StatFormat(Val){
            return `${Val.toFixed(0)}`;
        },
        VisitorStamp(){
            SiteDataService.VisitorStamp()
                .then(res => {
                    console.log(res.data);
                })
                .catch(err => {
                    console.log(err);
                });
        },
        GetSiteAnalyze(){
            SiteDataService.GetSiteAnalyze()
                .then(res => {
                    console.log(res.data);
                    this.site_stat.visitor = res.data[0].visitor_total;
                })
                .catch(err => {
                    console.log(err);
                });
        }
    },
    watch:{
        showLanguage(n){
            this.$cookies.set('showlang',n.lang,'7d');
            this.GetSiteData(n.lang);
            this.mdLanguage = false;
        }
    },
    created() {
        window.addEventListener("resize", this.windowSizeChange);
        window.addEventListener("scroll", this.windowScroll);
        this.VisitorStamp();
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.windowSizeChange);
        window.removeEventListener("scroll", this.windowScroll);
    },
    mounted(){
        this.sc_high = window.innerHeight;

        let cklang = this.$cookies.get('showlang');
        if(cklang == null)
            this.$cookies.set('showlang', 'en','7d');

        let shlang = (cklang != null ? cklang : "en");
        this.SetShowLanguage(shlang); 
        this.site_stat.yearexp = this.CalculateYearOfExp();              
        this.GetSiteAnalyze();
    }
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;600;700&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Reenie+Beanie&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kanit&display=swap");

.img-lang{
    max-width: 30px;
    height: auto;
}

.stat-number span{
    font-size: 30px;
}

.vl{
    border-left: 2px solid white;
    height: 60px;
    margin: 5px 5px;
    margin-top: -5px;
}
</style>